import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  name: 'terminal_scan_in_record',
  data() {
    return {
      requestType: 'GET',
    };
  },
  created() {
    this.getConfigList('terminal_scan_in_record');
  },

  methods: {

  },
};
